import gql from 'graphql-tag';

export default gql`
	query user($tokenIssuedAt: String!) {
		user(tokenIssuedAt: $tokenIssuedAt) {
			firstName
			lastName
			phoneNumber
			email
			country
			tokenIssuedAt
			locale
			firstLogin
			institution {
				id
				name
				image {
					url
					size
					createdAt
					updatedAt
				}
				color
				about
				analyticsCode
				isMerchant
			}
			analyticsToken
		}
	}
`;
